<template>
  <aside class="feed-aside feed-column" v-if="$screen.width >= 1040">
    <AdminMenu />
  </aside>
</template>

<script>
export default {
  components: {
    AdminMenu: () => import("../admin/AdminMenu.vue"),
    UserMenu: () => import("../user/UserMenu.vue"),
  },
};
</script>
